import React from "react";
import { useLatest } from "react-use";
import { unwrapResult } from "@reduxjs/toolkit";
import { ColumnType } from "antd/lib/table";
import { Button, Dropdown, Form, Menu, notification } from "antd";
import moment from "moment";
import { More } from "assets/icons";
import { DATE_FORMAT, EMPTY } from "constants/common";
import {
  message,
  Modal,
  RevokeVoucher,
  SwitchCourse,
  SwitchGroup,
  ViewProgress,
} from "components";
import { store, useAppDispatch } from "app/store";
import { useSelector } from "react-redux";
import useAuth0User from "hooks/useAuth0User";
import {
  AssignmentAndRedemptionModel,
  AssignmentModel,
  RedemptionModel,
  VoucherType,
} from "types/model/assignment";
import {
  reassignRedeemedSingleVoucher,
  reassignSeat,
  reassignVoucher,
  resetPassword,
  revokeVoucher,
  sendSeatEmail,
  sendVoucherEmail,
} from "../voucherGroupsSlice";
import AssignmentForm, { ReassignValues } from "../AssignmentForm";
import {
  customerGroupsSelectors,
  selectCurrentGroup,
} from "../../customerGroup/customerGroupsSlice";
import EmailIndicator from "./EmailIndicator";
import Indicator from "../VoucherDistributionList/Indicator";

function useColumns(
  voucherType: VoucherType,
  voucherGroupId: string,
  adminGroupId: number,
  resetFilter: () => void
) {
  const { isB2bAdmin } = useAuth0User();
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const currentGroup = useSelector(selectCurrentGroup);
  const customerGroups = customerGroupsSelectors.selectAll(store.getState());
  const canSwitchGroup =
    customerGroups.filter(
      (customerGroup) =>
        customerGroup.organizationId === currentGroup.organizationId
    ).length > 1;
  const latestVoucherType = useLatest(voucherType);

  const handleSendEmail = (record: AssignmentAndRedemptionModel) => {
    const { assigneeEmail, assignmentId, voucherId } = record;
    Modal.confirm({
      title: "Send email",
      content: `Are you sure you want to send on-boarding email to ${assigneeEmail}?`,
      okText: "Send",
      onOk: () => {
        if (latestVoucherType.current === "SEAT") {
          dispatch(sendSeatEmail([{ assignmentId, voucherId }]))
            .then(unwrapResult)
            .then(resetFilter)
            .catch(message.error);
        } else {
          dispatch(
            sendVoucherEmail({
              adminGroupId,
              assignmentIds: [assignmentId],
            })
          )
            .then(unwrapResult)
            .then(resetFilter)
            .catch(message.error);
        }
      },
    });
  };

  const showReassignModal = (
    { assigneeEmail, voucherId }: AssignmentAndRedemptionModel,
    onOk: (values: ReassignValues) => PromiseLike<any>
  ) => {
    Modal.confirm({
      title: "Reassign user",
      content: (
        <AssignmentForm
          form={form}
          assigneeEmail={assigneeEmail}
          voucherType={voucherType}
          isCreatingAuth0AccountForSeatUserEnabled={
            currentGroup?.isCreatingAuth0AccountForSeatUserEnabled
          }
        />
      ),
      okText: "Confirm",
      onOk: () => form.validateFields().then(onOk),
      afterClose: form.resetFields,
    });
  };

  const showRevokeSeatModal = (record: AssignmentAndRedemptionModel) => {
    const { assignmentId, voucherId } = record;
    Modal.confirm({
      title: "Revoke seat",
      content: "Are you sure you want to revoke this seat?",
      okText: "Confirm",
      onOk: () =>
        dispatch(revokeVoucher({ voucherId, assignmentId }))
          .then(unwrapResult)
          .then(() => resetFilter())
          .catch(message.error),
    });
  };

  const handleResetPassword = (email: string) => {
    Modal.confirm({
      title: "Send email",
      content: `Are you sure you want to send reset password email to ${email}?`,
      okText: "Send",
      onOk: () =>
        dispatch(resetPassword(email))
          .then(unwrapResult)
          .then(() => {
            notification.success({
              message: "Email Send！",
              description: `Reset password Email has been successfully send to ${email}`,
            });
          })
          .catch(message.error),
    });
  };

  const voucherColumnConfig: ColumnType<AssignmentAndRedemptionModel>[] = [
    {
      title: "Voucher code",
      dataIndex: "voucherCode",
      width: 120,
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: "Assigned email",
      dataIndex: "assigneeEmail",
      width: 140,
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: "User name",
      dataIndex: "assigneeName",
      width: 90,
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: "Redeemed email",
      dataIndex: "redeemerEmail",
      width: 140,
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: "Redeemed course",
      dataIndex: "redeemedCourse",
      width: 170,
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: "Email sent at",
      dataIndex: "emailSentDate",
      width: 100,
      ellipsis: true,
      render: (value, record) => {
        return (
          <span style={{ position: "relative" }}>
            <EmailIndicator
              style={{ position: "absolute", left: "-16px" }}
              deliveryStatus={record.deliveryStatus}
            />
            {value ? moment(value).format(DATE_FORMAT) : EMPTY}
          </span>
        );
      },
    },
    {
      title: "Expiration date",
      dataIndex: "entitlementExpirationDate",
      width: 100,
      ellipsis: true,
      render: (value, record) => {
        return (
          <div>
            <Indicator
              hasExpired={record.hasExpired}
              aboutToExpire={record.aboutToExpire}
            />
            {value ? moment(value).format(DATE_FORMAT) : EMPTY}
          </div>
        );
      },
    },
    {
      title: "Action",
      width: 100,
      ellipsis: true,
      render: (record: AssignmentAndRedemptionModel) => {
        return (record?.assigneeEmail || record?.redeemerEmail) &&
          !record.isDeleted &&
          !currentGroup?.isRegardedAsB2c ? (
          <Dropdown
            overlay={
              <Menu>
                {record?.redeemerEmail ? (
                  <>
                    <SwitchCourse record={record} resetFilter={resetFilter} />
                    {isB2bAdmin && (
                      <>
                        <Menu.Item
                          onClick={() =>
                            showReassignModal(record, (values) =>
                              dispatch(
                                reassignRedeemedSingleVoucher({
                                  ...values,
                                  adminGroupId,
                                  voucherId: record.voucherId,
                                })
                              )
                                .then(unwrapResult)
                                .then(resetFilter)
                                .catch(message.error)
                            )
                          }
                        >
                          Reassign user
                        </Menu.Item>
                        <RevokeVoucher
                          voucherId={record.voucherId}
                          resetFilter={resetFilter}
                          hasRedeemed={!!record?.redeemerEmail}
                        />
                      </>
                    )}
                    {canSwitchGroup && (
                      <SwitchGroup
                        voucherGroupId={voucherGroupId}
                        assignmentId={record?.assignmentId}
                        redemptionId={record?.redemptionId}
                      />
                    )}
                    {currentGroup?.isAnalyticsReportingEnabled && (
                      <ViewProgress email={record?.redeemerEmail} />
                    )}
                  </>
                ) : (
                  <>
                    <Menu.Item onClick={() => handleSendEmail(record)}>
                      Send email
                    </Menu.Item>
                    <Menu.Item
                      onClick={() =>
                        showReassignModal(record, (values) =>
                          dispatch(
                            reassignVoucher({
                              ...values,
                              adminGroupId,
                              voucherId: record.voucherId,
                            })
                          )
                            .then(unwrapResult)
                            .then(resetFilter)
                            .catch(message.error)
                        )
                      }
                    >
                      Reassign user
                    </Menu.Item>
                    <RevokeVoucher
                      voucherId={record.voucherId}
                      resetFilter={resetFilter}
                    />
                    {canSwitchGroup && (
                      <SwitchGroup
                        voucherGroupId={voucherGroupId}
                        assignmentId={record?.assignmentId}
                      />
                    )}
                  </>
                )}
              </Menu>
            }
          >
            <More />
          </Dropdown>
        ) : (
          EMPTY
        );
      },
    },
  ];

  const seatColumnConfig: ColumnType<AssignmentAndRedemptionModel>[] = [
    {
      title: "Assigned email",
      dataIndex: "assigneeEmail",
      width: 160,
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: "User name",
      dataIndex: "assigneeName",
      width: 90,
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: "Assigned at",
      dataIndex: "assignedDate",
      width: 90,
      ellipsis: true,
      render: (value) => (value ? moment(value).format(DATE_FORMAT) : EMPTY),
    },
    {
      title: "Course",
      dataIndex: "redeemedCourse",
      width: 160,
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: "Email sent at",
      dataIndex: "emailSentDate",
      width: 90,
      ellipsis: true,
      render: (value, record) => {
        return (
          <span style={{ position: "relative" }}>
            <EmailIndicator
              style={{ position: "absolute", left: "-16px" }}
              deliveryStatus={record.deliveryStatus}
            />
            {value ? moment(value).format(DATE_FORMAT) : EMPTY}
          </span>
        );
      },
    },
    {
      title: "Account created",
      dataIndex: "accountCreated",
      width: 110,
      ellipsis: true,
      render: (value) => (value ? "Yes" : value === false ? "No" : EMPTY),
    },
    {
      title: "Expiration date",
      dataIndex: "entitlementExpirationDate",
      width: 100,
      ellipsis: true,
      render: (value, record) => {
        return (
          <div>
            <Indicator
              hasExpired={record.hasExpired}
              aboutToExpire={record.aboutToExpire}
            />
            {value ? moment(value).format(DATE_FORMAT) : EMPTY}
          </div>
        );
      },
    },
    {
      title: "Action",
      width: 90,
      ellipsis: true,
      render: (record: AssignmentAndRedemptionModel) => {
        return record.isDeleted ? (
          EMPTY
        ) : (
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={() => handleSendEmail(record)}>
                  Send email
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    showReassignModal(record, (values) =>
                      dispatch(
                        reassignSeat({
                          ...values,
                          assignmentId: record.assignmentId,
                          productId: record.productId,
                        })
                      )
                        .then(unwrapResult)
                        .then(resetFilter)
                        .catch(message.error)
                    )
                  }
                >
                  Reassign user
                </Menu.Item>
                <SwitchCourse record={record} resetFilter={resetFilter} />
                <Menu.Item onClick={() => showRevokeSeatModal(record)}>
                  Revoke seat
                </Menu.Item>
                <Menu.Item
                  onClick={() =>
                    handleResetPassword(record?.assigneeEmail as string)
                  }
                >
                  Reset password
                </Menu.Item>
                {canSwitchGroup && (
                  <SwitchGroup
                    voucherGroupId={voucherGroupId}
                    assignmentId={record?.assignmentId}
                  />
                )}
                {currentGroup?.isAnalyticsReportingEnabled && (
                  <ViewProgress email={record?.assigneeEmail!} />
                )}
              </Menu>
            }
          >
            <More />
          </Dropdown>
        );
      },
    },
  ];

  const masterRedemptionColumnConfig: ColumnType<RedemptionModel>[] = [
    {
      title: "Redeemed email",
      dataIndex: "redeemerEmail",
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: "Redeemed course",
      dataIndex: "redeemedCourse",
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: "Redeemed date",
      dataIndex: "redeemedDate",
      ellipsis: true,
      render: (value) => (value ? moment(value).format(DATE_FORMAT) : EMPTY),
    },
    {
      title: "Expiration date",
      dataIndex: "entitlementExpirationDate",
      ellipsis: true,
      render: (value, record) => {
        return (
          <div>
            <Indicator
              hasExpired={record.hasExpired}
              aboutToExpire={record.aboutToExpire}
            />
            {value ? moment(value).format(DATE_FORMAT) : EMPTY}
          </div>
        );
      },
    },
    {
      title: "Action",
      render: (record) => {
        return record.isDeleted ? (
          EMPTY
        ) : (
          <Dropdown
            overlay={
              <Menu>
                <SwitchCourse record={record} resetFilter={resetFilter} />
                {currentGroup?.isAnalyticsReportingEnabled && (
                  <ViewProgress email={record?.redeemerEmail} />
                )}
              </Menu>
            }
          >
            <More />
          </Dropdown>
        );
      },
    },
  ];

  const masterAssignmentColumnConfig: ColumnType<AssignmentModel>[] = [
    {
      title: "Assigned email",
      dataIndex: "assigneeEmail",
      width: "25%",
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: "User name",
      dataIndex: "assigneeName",
      width: "19%",
      ellipsis: true,
      render: (value) => value || EMPTY,
    },
    {
      title: "Assigned date",
      dataIndex: "assignedDate",
      width: "25%",
      ellipsis: true,
      render: (value) => (value ? moment(value).format(DATE_FORMAT) : EMPTY),
    },
    {
      title: "Email sent at",
      width: "16%",
      dataIndex: "emailSentDate",
      render: (value, record) => {
        return (
          <span style={{ position: "relative" }}>
            <EmailIndicator
              style={{ position: "absolute", left: "-16px" }}
              deliveryStatus={record.deliveryStatus}
            />
            {value ? moment(value).format(DATE_FORMAT) : EMPTY}
          </span>
        );
      },
    },
    {
      title: "Action",
      width: "15%",
      render: (record) => {
        return record.isDeleted ? (
          EMPTY
        ) : (
          <Button type="link" onClick={() => handleSendEmail(record)}>
            Send email
          </Button>
        );
      },
    },
  ];
  return {
    voucherColumnConfig,
    seatColumnConfig,
    masterRedemptionColumnConfig,
    masterAssignmentColumnConfig,
  };
}

export default useColumns;
