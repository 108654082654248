import React, { useEffect, useState } from "react";
import { Layout, Menu, Tooltip } from "antd";
import { LeftCircleOutlined, RightCircleOutlined } from "@ant-design/icons";
import { MenuInfo } from "rc-menu/lib/interface";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import menuConfigs from "app/menu";
import { MenuConfig } from "types/menu";
import styles from "./Sider.module.scss";
import { RootState } from "../../app/store";
import { selectCurrentGroup } from "../../features/customerGroup/customerGroupsSlice";

function Sider() {
  const history = useHistory();
  const { pathname } = useLocation();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { currentGroupId: adminGroupId } = useSelector(
    (state: RootState) => state.customerGroups
  );
  const currentGroup = useSelector(selectCurrentGroup);

  useEffect(() => {
    if (currentGroup.isRegardedAsB2c && !pathname.includes("/assignment")) {
      history.push("/");
    }
  }, [pathname, currentGroup.isRegardedAsB2c]);

  const renderNavItem = (config: MenuConfig, parentPath: string = "") => {
    const { title } = config;

    if (!config.children) {
      return (
        <Menu.Item key={parentPath + config.path} icon={config.icon}>
          {title}
        </Menu.Item>
      );
    } else {
      return (
        <Menu.SubMenu title={title} key={config.path} icon={config.icon}>
          {config.children.map((childConfig) =>
            renderNavItem(childConfig, config.path)
          )}
        </Menu.SubMenu>
      );
    }
  };

  const handleClick = (e: MenuInfo) => {
    history.push(`/${adminGroupId}${e.key as string}`);
  };

  const handleSiderCollapse = (collapsed: boolean) => {
    setIsCollapsed(collapsed);
  };

  return (
    <Layout.Sider
      collapsible
      trigger={
        isCollapsed ? (
          <Tooltip placement="top" title="Expand">
            <RightCircleOutlined />
          </Tooltip>
        ) : (
          <Tooltip placement="top" title="Collapse">
            <LeftCircleOutlined />
          </Tooltip>
        )
      }
      collapsed={isCollapsed}
      onCollapse={handleSiderCollapse}
      theme="light"
      className={styles.navbar}
    >
      <Menu
        mode="inline"
        defaultSelectedKeys={[
          menuConfigs[0]?.children
            ? menuConfigs[0].path + menuConfigs[0].children[0].path
            : menuConfigs[0]?.path,
        ]}
        defaultOpenKeys={[menuConfigs[0]?.children ? menuConfigs[0]?.path : ""]}
        selectedKeys={menuConfigs
          .map((config) => config.path)
          .filter((path) => pathname.includes(path))}
        onClick={handleClick}
      >
        {menuConfigs
          .filter((config) => {
            return (
              !currentGroup?.isRegardedAsB2c || config.path === "/assignment"
            );
          })
          .map((config) => renderNavItem(config))}
      </Menu>
    </Layout.Sider>
  );
}

export default Sider;
