import React from "react";
import { ColumnType } from "antd/lib/table";
import moment from "moment";
import { EMPTY, VOUCHER_TYPE_MAP } from "constants/common";
import { VoucherGroupModel } from "types/model/assignment";
import Actions from "./Actions";
import styles from "./VoucherGroupList.module.scss";
import Indicator from "./Indicator";

const useColumns = (canDistribute: boolean) => {
  const columns: ColumnType<VoucherGroupModel>[] = [
    {
      title: "Invoice number",
      dataIndex: "invoice",
      ellipsis: true,
      width: canDistribute ? "20%" : "25%",
      render: (value) => value || EMPTY,
    },
    {
      title: "Vouchers description",
      dataIndex: "description",
      ellipsis: true,
      width: canDistribute ? "20%" : "25%",
      render: (value) => value || EMPTY,
    },
    {
      title: "Voucher type",
      dataIndex: "voucherType",
      width: 140,
      render: (value) => VOUCHER_TYPE_MAP.get(value) || EMPTY,
    },
    {
      title: "Total licenses",
      dataIndex: "voucherAmount",
      width: 140,
      render: (value) => value ?? EMPTY,
    },
    {
      title: "Remaining licenses",
      dataIndex: "remainingLicenses",
      width: 140,
      render: (value) => value ?? EMPTY,
    },
    {
      title: "Start date",
      dataIndex: "startDate",
      width: 140,
      render: (value) => (value ? moment(value).format("YYYY-MM-DD") : EMPTY),
    },
    {
      title: "End date",
      dataIndex: "expirationDate",
      width: 140,
      render: (value, record) => {
        return (
          <div>
            <Indicator
              hasExpired={record.hasExpired}
              aboutToExpire={record.aboutToExpire}
            />
            {value ? moment(value).format("YYYY-MM-DD") : EMPTY}
          </div>
        );
      },
    },
    {
      title: "Action",
      width: "10%",
      onCell: () => ({ onClick: (e) => e.stopPropagation() }),
      className: styles.action,
      render: (record) => <Actions record={record} />,
    },
  ];

  return canDistribute
    ? columns
    : columns.filter((column) => column.title !== "Action");
};

export default useColumns;
