export interface CustomerGroupDto {
  id: number;
  isAnalyticsReportingEnabled: boolean;
  isCreatingSubgroupEnabled: boolean;
  isCreatingAuth0AccountForSeatUserEnabled: boolean;
  isRegardedAsB2c: boolean;
  organizationId: number;
  pId: number;
  value: string;
  title: string;
}

interface ExternalAdmin {
  customerId?: number;
  email: string;
  hasSentEmail?: boolean;
  lastEmailSentDate?: number;
  name: string;
  telephone: string;
}

export interface CustomerGroupResponse {
  adminGroups?: Array<CustomerGroupDto>;
  lastSelectedGroupId: number;
}

export class CustomerGroupDetailResponse {
  allowedLoginDomains?: string;
  description?: string;
  editable?: boolean;
  groupAdmins?: Array<ExternalAdmin>;
  id: number;
  isAnalyticsReportingEnabled?: boolean;
  isCreatingSubgroupEnabled?: boolean;
  name?: string;

  constructor(data: CustomerGroupDetailResponse) {
    Object.assign(this, data);
    this.id = data.id;
  }
}

export interface SubgroupListResponse {
  id: number;
  isAnalyticsReportingEnabled?: boolean;
  isCreatingSubgroupEnabled?: boolean;
  lastModifiedDate?: number;
  name?: string;
}
